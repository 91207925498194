.control {
  display: block;
}

.field {
  display: block;
}

.phoneNumber {
  display: flex;
  position: relative;

  button {
    border-right: 0;
    padding-left: 10px;
  }

  input[type='tel'] {
    width: 100%;
    border-left: 0;
  }
}
