.Divider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.Divider::before,
.Divider::after {
  content: '';
  flex: 1;
  border-bottom: 2px solid var(--ld-ref-palette-neutral-variant40);
}

.Divider:not(:empty)::before {
  margin-right: 0.1em;
}

.Divider:not(:empty)::after {
  margin-left: 0.1em;
}
