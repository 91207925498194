.Root {
  width: 100%;
}

.container {
  margin: 2rem auto;

  //ipad screen only
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin: 1.5rem auto;
  }

  //desktop screen
  @media only screen and (min-width: 1025px) {
    display: flex;
    width: calc(100% - var(--main-menu-width));
    right: 0;
    position: absolute;
    margin: 2rem auto;
    top: calc(var(--safe-area-top, 0px) + var(--menu-height));
    padding-bottom: 1rem;
  }
}
.overlay {
  position: absolute;
  top: 0;
  height: 300px;
  border-radius: 8px;
  background-color: rgb(3, 34, 101);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  width: 100%;
  cursor: pointer;
  padding-top: 30%;

  h4 {
    font-size: 40px;
    color: white;

    @media (max-width: 412px) {
      font-size: 34px;
    }
  }
  p {
    color: white;
    font-weight: 400;
    text-align: center;
  }
}
