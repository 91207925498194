:root {
  --header-height: 80px;
  --menu-height: 60px;
}
.Header {
  width: 100%;
  height: var(--header-height);
  background-color: white;
  display: flex;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(128, 128, 128, 0.2);

  &.Inverted {
    background-color: #032265;

    h1 {
      color: white;
      max-width: calc(100vw - 60px);
    }
  }
  &.right {
    h1 {
      margin-right: 0;
    }
  }
  &.left {
    h1 {
      margin-left: 0;
    }
  }
  &.fixed {
    position: fixed;
    top: var(--safe-area-top, 0px);
    z-index: 100;
    margin-top: 0px;
    text-transform: uppercase;

    h1 {
      text-transform: uppercase;
    }

    //from ipad screen
    @media only screen and (min-width: 768px) {
      top: calc(var(--menu-height) + var(--safe-area-top, 0px));
      position: fixed;
      z-index: 10;
    }

    //desktop screen
    @media only screen and (min-width: 1025px) {
      top: var(--safe-area-top, 0px);
      margin-top: 0;
      width: calc(100% - var(--main-menu-width));
      position: fixed;
      right: 0;
    }
  }
  &.Lined {
    border-top: 2px solid #85c8f9;
    border-bottom: 2px solid #85c8f9;
    margin: 15px 0;
  }
  .BackButton {
    cursor: pointer;
    color: white;
    text-decoration: none;
    font-size: 2rem;
    position: absolute;
    left: 18px;
    top: 15px;
    @media (max-width: 512px) {
      left: 10px;
    }
  }

  .HeaderWrapper {
    width: 100%;
    height: auto;
    padding: 5px 15px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .HeaderTitle {
      display: flex;

      h2 {
        padding: 0 12px;
      }
    }
    .HeaderSubtitle {
      display: flex;
      justify-content: space-between;
    }
  }

  h1 {
    margin: auto;
    text-transform: none;
  }

  /* Styles for desktop screens */
  @media screen and (min-width: 1025px) {
    top: 0;
  }
}
.HeaderSpacer {
  height: var(--header-height);

  /* Styles for iPad screens */
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: calc(
      var(--menu-height) + var(--safe-area-top, 0px) + var(--header-height)
    );
  }
}
