:root {
  --header-height: 80px;
  padding-bottom: var(--safe-area-bottom);
}

.contentContainer {
  @media (min-width: 1025px) {
    position: absolute;
    width: calc(100% - var(--main-menu-width));
    right: 0;
  }
}

.trialContentContainer {
  margin: 1.5rem auto;

  @media (min-width: 1025px) {
    position: absolute;
    width: calc(100% - var(--main-menu-width));
    right: 0;
    // top: var(--safe-area-top, 0px);
  }
}

.HundredLovingWayBlockContent {
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--page-color);
  padding: 5px 20px 20px 20px;
}
@media screen and (max-width: 320px) {
  .HundredLovingWayBlockContent {
    padding: 5px 20px 20px 20px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1920px) {
  .HundredLovingWayBlockContent {
    padding: 5px 100px 20px 100px;
    div {
      margin: 0 auto;
      width: 600px;
    }
  }
}

.Team {
  display: flex;
  flex-direction: column;
  overflow: auto;
  // background-color: var(--page-color);
  //padding: 5px 20px 20px 20px;

  /* Styles for iPad screens */
  @media screen and (min-width: 768px) {
    // margin-top: 5rem;
    top: calc(var(--safe-area-top, 0px) + var(--menu-height));
    position: absolute;
  }
  /* Styles for desktop screens */
  @media only screen and (min-width: 1025px) {
    margin-top: 0;
    top: 0;
    width: calc(100% - var(--main-menu-width));
    // position: absolute;
    right: 0;
  }
}
//@media screen and (max-width: 320px) {
//  .HundredLovingWayBlockContent {
//    padding: 5px 20px 20px 20px;
//  }
//}
//
//@media screen and (min-width: 1024px) and (max-width: 1920px) {
//  .HundredLovingWayBlockContent {
//    padding: 5px 100px 20px 100px;
//  }
//}

.Link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  a {
    font-family: Anton;
    color: var(--secondary-color);
    font-size: 20px;
  }
}
//@media screen and (max-width: 320px) {
//  .HundredLovingWayBlockContent {
//    padding: 5px 20px 20px 20px;
//  }
//}
//
//@media screen and (min-width: 1024px) and (max-width: 1920px) {
//  .HundredLovingWayBlockContent {
//    padding: 5px 100px 20px 100px;
//  }
//}

.Message {
  align-items: center;
  justify-content: center;
  padding: 20px 30px;

  @media screen and (min-width: 1025px) {
    left: 20px;
    position: relative;
  }

  .TextArea {
    padding: 15px;
    border-radius: 15px 15px 0 15px;
    width: 100%;
    height: 130px;
    box-sizing: border-box;
    // border: none;
    // border: 2px solid transparent;
    resize: none;

    &:focus {
      border-color: var(--secondary-color);
      outline: none;
    }

    &::placeholder {
      font-family: Roboto;
      font-size: 16px;
    }
  }
}

.NextMeeting {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .Header {
    display: flex;
    background-color: white;
    margin: 0 20px;

    .Title {
      padding: 10px 10px;
      box-sizing: border-box;
      margin: 0 auto;
    }
  }

  .FormContainer {
    margin: 20px;
    button {
      margin-top: 20px;
      width: 100%;
    }
    .FormMeeting {
      display: flex;
      margin-bottom: 10px;
      gap: 10px;
      flex-direction: column;
      label {
        font-size: 14px;
        font-weight: bold;
      }

      .inputDate {
        width: 100%;
      }
    }
  }

  .Content {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 5px;
    padding: 20px 30px;

    .GoogleMap {
      width: 500px;
      height: 500px;
      position: relative;
      overflow: hidden;
    }

    .GoogleMap {
      width: 500px;
      height: 500px;
      position: relative;
      overflow: hidden;
    }

    .GoogleMap {
      width: 500px;
      height: 500px;
      position: relative;
      overflow: hidden;
    }

    .TextContainer {
      width: 50%;

      .TopicName {
        font-size: 24px;
      }

      .DateTime {
        font-size: 18px;
      }

      @media screen and (max-width: 520px) {
        .TopicName {
          font-size: 16px;
        }
        .DateTime {
          font-size: 14px;
        }
      }
    }
    .noMeeting {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 20px;
      font-weight: bold;
      h3 {
        color: var(--secondary-color);
      }
    }

    .TopicName {
      display: flex;
      label {
        color: white;
        font-size: 14px;
      }
    }
  }
}

//BlockScoreboard Section
.BlockScoreboard {
  display: flex;
  flex-direction: column;

  .Header {
    display: flex;
    background-color: white;

    .Title {
      padding: 10px 10px;
      box-sizing: border-box;
    }
  }

  .Content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;

    .ScoreboardTable {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;

      .TableHeader {
        display: flex;

        div {
          display: flex;
          flex-direction: column;
          flex: 1;
          width: 33.33%;
          align-items: center;
          justify-content: space-between;
        }
      }

      .TableContent {
        display: flex;

        div {
          display: flex;
          justify-content: center;
          flex: 1;
          width: 33.33%;

          &:nth-of-type(2) {
            align-items: center;
            justify-content: center;

            h3 {
              font-size: 20px;
            }
          }

          &:nth-of-type(3) {
            align-items: center;
            justify-content: center;

            h3 {
              font-size: 20px;
            }
          }

          .BlockScoreboardTopicThumbnail {
            margin: 10px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

.loading {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonBlock {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;

  button {
    border-color: #fff !important;
  }
}
