.line_1,
.line_2 {
  height: 9px;
  width: 130px;
  border-top: 2px solid var(--main-color);
  border-bottom: 2px solid var(--main-color);
  position: absolute;
  background-color: var(--main-color);
  z-index: 1;
}
.lineActive {
  background-color: var(--secondary-color);
}

.line_1 {
  right: 150px;
  top: 15px;
}
.line_2 {
  left: 150px;
  top: 15px;
}
.line2 {
  z-index: 1;
}
.line_1a {
  right: 157px;
  top: 15px;
}
.line_2a {
  left: 157px;
  top: 15px;
}
.line_1a,
.line_2a {
  height: 5px;
  width: 110px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 2px;
  z-index: 4;
}
.lineActive2 {
  background-color: var(--secondary-color);
}
.tabs,
.stepsContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.stepsContainer p {
  font-family: 'Anton', 'serif';
  text-transform: capitalize;
  &:first-child {
    margin-left: 4px;
  }
  &:last-child {
    margin-right: 4px;
  }
  &.active {
    color: var(--secondary-color);
  }
}

.tab {
  padding: 5px 8px 10px 8px;
  width: 20px;
  height: 20px;
  background-color: var(--main-color);
  color: var(--ld-app-color-on-primary);
  border: 2px solid var(--main-color);
  border-radius: 50%;
  box-sizing: content-box;
  z-index: 2;
  font-size: 20px;
  font-weight: bold;
  &:hover {
    background-color: var(--secondary-color);
    transition-duration: 500ms;
    cursor: pointer;
  }
}

.tabActive {
  background-color: var(--secondary-color) !important;
}
.tabsContainer {
  width: 300px;
  margin: auto;
}
