.root {
  display: flex;
  gap: 1rem;
}

.icon {
  width: 25px;
  height: 25px;
}

.WhatsappIcon {
  width: 25px;
  height: 25px;
}

.phoneInput {
  width: 100%;
  height: 48px;
  border-radius: 7px;
  border: 1px solid #222;
  text-indent: 10px;
}

.signButton {
  width: 32px;
  height: 32px;
  position: absolute;
  right: calc(100% - 94%);
}

.loading {
  width: 32px;
  height: 32px;
  margin: 0 auto;
}
