.returnButton {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  color: white;
  position: fixed;
  border-radius: 0;
  z-index: 99999;
}
.banner {
  width: 100%;
  object-fit: cover;
  height: 650px;
  max-height: 50vh;
  @media (min-width: 769px) and (max-width: 1024px) {
    height: 500px;
  }
  @media (max-width: 768px) {
    height: 300px;
  }
}
.headerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > h2 {
    color: var(--secondary-color);
    margin-top: 1rem;
  }
  & > h5 {
    color: var(--main-color);
    text-transform: capitalize;
    margin: 0.5rem;
  }
}

@media only screen and (max-width: 760px) {
  .returnButton {
    font-size: 0.72rem;
  }
}
