:root {
  --ld-app-color-on-primary: --main-color;
  --menu-height: 60px;
}

.MenuContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;

  //ipad screen
  @media only screen and (min-width: 768px) {
    justify-content: space-between;
  }
  //desktop screen
  @media only screen and (min-width: 1025px) {
    flex-direction: column;
    // position: relative;
    align-self: flex-start;
  }
}

.Logo {
  display: none;

  //ipad screen for title only
  @media only screen and (min-width: 768px) and (max-width: 795px) {
    h2 {
      font-size: 20px;
    }
  }

  //ipad screen
  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    img {
      width: 50px;
    }

    h2 {
      text-transform: capitalize;
      color: var(--main-color);
    }
  }

  //desktop screen
  @media only screen and (min-width: 1025px) {
    margin-bottom: 2.5rem;
    h2 {
      font-size: 25px;
    }
  }
}

.Nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  width: 100%;
  height: 100%;
  a {
    text-decoration: none;
  }

  //ipad screen
  @media only screen and (min-width: 768px) {
    width: auto;
  }

  //desktop screen
  @media only screen and (min-width: 1025px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5rem;
  }

  //cursor: pointer;
  > div {
    //display: flex;
    //flex-direction: column;
    align-items: center;
    //justify-content: center;
    cursor: pointer;
    text-align: center;

    //ipad screen
    @media only screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }

    p {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;

      //desktop screen
      @media only screen and (min-width: 1025px) {
        font-size: 15px;
      }
    }
  }
  & div:hover,
  div:active {
    p {
      color: var(--secondary-color, #00a6cb);
    }
    img {
      filter: invert(52%) sepia(34%) saturate(4332%) hue-rotate(156deg)
        brightness(92%) contrast(103%);
    }
  }
}

.Menu {
  background-color: var(--ld-app-color-on-primary);
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin: auto;
  position: fixed;
  bottom: var(--safe-area-bottom, 0);
  width: 100%;
  left: 0;
  z-index: 99;
  height: var(--menu-height);

  /* Styles for iPad screens */
  @media screen and (min-width: 768px) {
    position: fixed;
    top: var(--safe-area-top, 0px); /* Position on the top for iPad */
    bottom: auto; /* Reset the bottom position */
  }
  //desktop screen
  @media only screen and (min-width: 1025px) {
    width: var(--main-menu-width);
    background-color: white;
    height: 100%;
    z-index: 100;
    padding-top: 20px;
  }

  .ButtonMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 10px !important;
  }
}
.MenuSpacing {
  height: 62px;
  width: 100%;

  //ipad screen
  @media only screen and (min-width: 768px) {
    // height: auto;
    display: none;
  }
}

.navContainer {
  display: block;
  text-align: center;
  p {
    text-transform: uppercase;
    font-size: 12px;
  }
  &.active {
    p {
      color: var(--secondary-color);
    }
    img {
      filter: invert(52%) sepia(34%) saturate(4332%) hue-rotate(156deg)
        brightness(92%) contrast(103%);
    }
  }

  //ipad screen
  @media only screen and (min-width: 768px) {
    display: flex;
    width: auto;
    gap: 8px;
    align-items: center;
  }

  //desktop screen
  @media only screen and (min-width: 1025px) {
    p {
      font-size: 18px;
    }
  }
}
