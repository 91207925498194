.ListItem {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  max-width: 900px;
  gap: 1rem;
}

.SingleListItem {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.GridListItem {
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  box-sizing: border-box;
}

/* Styles for iPad screens */
// @media screen and (min-width: 768px) {
//   .SingleListItem {
//     margin-top: 4rem;
//   }
// }
