.Title {
  display: flex;
  // justify-content: center;
  align-items: center;
  gap: 10px;

  img {
    width: 65px;
  }

  .Subtitle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    box-sizing: border-box;

    p {
      font-size: 10px;
    }
  }
}
