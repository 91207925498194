:root {
  --ld-button-text-primary-hover-background-color: var(
    --ld-ref-palette-primary95
  );
  --ld-button-text-secondary-hover-background-color: var(
    --ld-ref-palette-secondary98
  );
  --ld-button-text-tertiary-hover-background-color: var(
    --ld-ref-palette-tertiary95
  );

  // --ld-button-border-radius: var(--ld-ref-border-radius-xl);
  // --ld-form-border-radius: var(--ld-ref-border-radius-xl);
  --ld-button-filled-secondary-background-color: var(
    --ld-ref-palette-neutral-variant20
  );
  --ld-button-filled-secondary-border-color: var(
    --ld-ref-palette-neutral-variant20
  );
  --ld-button-filled-secondary-font-color: var(
    --ld-ref-palette-neutral-variant100
  );
  --ld-button-filled-secondary-hover-background-color: var(
    --ld-ref-palette-neutral-variant30
  );

  --ld-button-filled-primary-hover-background-color: var(--main-color);

  // --ld-button-border-radius: var(--ld-ref-border-radius-xl);
  --ld-button-filled-secondary-background-color: var(
    --ld-ref-palette-neutral-variant90
  );
  --ld-button-filled-secondary-border-color: var(
    --ld-ref-palette-neutral-variant90
  );
  --ld-button-filled-secondary-font-color: var(
    --ld-ref-palette-neutral-variant20
  );
  --ld-button-filled-secondary-hover-background-color: var(
    --ld-ref-palette-neutral-variant80
  );

  // signinWithPassword component color variable overwrites
  --ld-auth-text-hyperlink: var(--ld-ref-palette-neutral100);
  // --ld-auth-form-border-radius: var(--ld-ref-border-radius-xl);
  --ld-auth-text-color-error: var(--ld-ref-palette-error60);
  // --ld-auth-modal-border-radius: var(--ld-ref-border-radius-xl);
  --ld-auth-modal-background-color: var(--ld-ref-palette-neutral-variant95);
  --ld-slider-color: var(--ld-app-color-secondary-darker);
  --ld-paragraph-text-color-primary: var(--ld-ref-palette-neutral35);

  //fonts
  --ld-button-font-family: 'Anton', 'serif';

  //buttons
  --ld-button-filled-primary-background-color: var(--main-color);
  --ld-button-filled-primary-font-color: var(--ld-ref-palette-secondary100);
  --ld-button-filled-primary-border-color: var(--main-color);
  --ld-button-filled-primary-hover-background-color: var(--main-color);

  --ld-button-filled-secondary-background-color: var(--secondary-color);
  --ld-button-filled-secondary-font-color: var(--ld-ref-palette-secondary100);
  --ld-button-filled-secondary-hover-background-color: var(--secondary-color);

  // lincd input field
  --lincd-input-inputfield-text-size: 16px;

  // react-international-phone
  --react-international-phone-height: 50px;
  --react-international-phone-country-selector-border-color: var(--main-color);
  --react-international-phone-border-color: var(--main-color);
  --react-international-phone-border-radius: 0;
  --react-international-phone-font-size: 1rem;
}
