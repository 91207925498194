.title {
  border-bottom: 1px solid black;
  width: 100%;
  padding-top: 30px;
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
}

.textFieldContainer {
  display: flex;
  position: relative;
  padding: 10px 0;

  & > p {
    font-family: 'Anton', 'serif';
    font-size: 18px;
    width: 100%;
    text-align: left;
    text-wrap: nowrap;
    text-transform: capitalize;
  }
}

.locationText > p {
  font-family: 'Anton', 'serif';
  font-size: 18px;
  width: 90%;
  margin-left: 0;
}

.icon {
  position: absolute;
  top: 10px;
  right: 0;
  fill: var(--main-color);

  &:hover {
    cursor: pointer;
  }
}
