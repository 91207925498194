.CertificatePDF {
  display: block;
}
.CertificateButton {
  display: block;
  margin: 0 auto;
  padding: var(--ld-button-padding-y) var(--ld-button-padding-x);
  background-color: var(--ld-button-outlined-primary-background-color);
  border: solid 1px var(--ld-button-outlined-primary-border-color);
  border-radius: 5px;
  font-family: var(--ld-button-font-family);
  font-size: var(--ld-button-font-size);
  font-weight: var(--ld-button-font-weight);
  line-height: var(--ld-button-line-height);
  color: var(--ld-button-outlined-primary-font-color);
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: var(--ld-button-outlined-primary-hover-background-color);
  }
  &:active {
    background-color: var(--ld-button-outlined-primary-active-background-color);
  }
}
