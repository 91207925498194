.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;

  > img {
    width: 9.5rem;
  }
  > h1 {
    color: #fff;
  }
}

.statusContainer {
  display: flex;
  margin: 0 auto;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;

  @media only screen and (min-width: 1024px) {
    flex-wrap: nowrap;
  }

  div p {
    font-family: 'Anton', 'serif';
  }
}

.status {
  text-align: center;
  padding-bottom: 2rem;
  flex: 0 0 50%;

  @media only screen and (min-width: 1024px) {
    flex: 1;
  }

  > .title,
  p {
    color: #fff;
  }

  .title {
    font-size: 35px;
    margin-bottom: 15px;
  }

  p {
    text-transform: uppercase;
    font-size: 14px;
  }
}
