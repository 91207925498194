@import 'scss/variables.scss';

.App {
  display: block;
  color: var(--ld-app-body-text-color);
  background-color: var(--ld-app-body-background-color);
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  //outline:1px blue solid;
}
input {
  font-size: 1rem;
}

.team {
  background-color: var(--main-color);
}
