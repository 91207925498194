.container {
  max-width: var(--maxWidth);
  margin: 50px auto;
  padding: var(--contentPadding);
}

.iframe {
  width: 100%;
  height: 80vh;
  min-height: 80vh;
  border: none;
  margin: 2rem auto;
}
