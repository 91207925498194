//desktop screen
.mainContainer {
  @media only screen and (min-width: 1025px) {
    width: calc(100% - var(--main-menu-width));
    right: 0;
    position: absolute;
  }
}

.profileContainer {
  background-color: var(--page-color);
  text-align: center;
  padding: var(--containerPadding);
  padding-bottom: 85px;
  position: relative;
}

.inputContainer {
  font-family: 'Anton', sans-serif;
  text-align: start;
  max-width: 600px;
  margin: auto;
  padding: var(--containerPadding);
}

.medalContainer {
  width: 100%;
}

.subContainer {
  margin-bottom: 4rem;
  .avatarBox {
    position: relative;
    padding-bottom: 120px;
  }
  .avatar {
    width: 100px;
    top: 15px;
    height: 100px;
    border-radius: 10px;
    position: absolute;
    left: 0;
  }
}

.profileFormSection {
  padding-top: 1.5rem;
  // padding-bottom: 1.5rem;

  @media only screen and (min-width: 1025px) {
    padding-top: 0;
  }
}

.peaceActionSection {
  background-color: #032265;
  padding-bottom: 2rem;
  margin-top: 2rem;
}

.resetHeadline {
  margin-top: 0 !important;
  border-top: 0 !important;
}

.switchTeamSection {
  display: flex;
}

.scrollingHeader {
  //ipad screen
  @media only screen and (min-width: 768px) {
    position: relative; /* or static, depending on your layout */
    overflow: hidden; /* or auto, depending on your design */
  }
}

.teamActionButton {
  //display: flex;
  //align-items: center;
  //justify-content: space-evenly;
  gap: 10px;
  font-family: 'Roboto', 'sans-serif';
  font-size: 14px;
  text-wrap: nowrap;
  border: 2px solid var(--main-color) !important;
  color: var(--main-color) !important;
  z-index: 5;
  height: 50px;
  width: 50%;
  text-transform: uppercase;
  font-weight: 500 !important;
  &:hover {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    background-color: var(--soft-light-teal) !important;
  }
  &.left {
    left: 20%;
  }
  &.right {
    right: 20%;
  }
}

.inputWrapper {
  position: relative;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  // max-width: 125px;

  select {
    border: 1px solid var(--main-color);
    // text-transform: capitalize;
    border-radius: 0;
    padding: 0px 10px;
    font-size: 20px; // set on App.scss
    // height: 39px;

    &:focus {
      box-shadow:
        inset 1px 2px 4px rgba(0, 0, 0, 0.01),
        0px 0px 8px rgba(0, 0, 0, 0.2);
    }
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
}

.title {
  border-bottom: 1px solid black;
  width: 100%;
  padding-top: 30px;
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
}

.teamSelect {
  height: 50px !important;
}
