.SigninContainer {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  justify-content: space-evenly;
  gap: 10px;
  height: 100vh;

  @media only screen and (max-width: 480px) {
    /* Media query for iOS devices or devices with a screen width up to 480 pixels */
    gap: 30px; // Set gap to 30px on mobile screens
    // height: auto; // Remove the height property on mobile screens
  }

  .Form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 18px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .el_input {
    border: 1px solid var(--lincd-color-primary);
    border-radius: 100px;
  }

  .Title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 0.5rem;

    img {
      width: 150px;
    }

    h2 {
      text-transform: capitalize;
      font-size: 34px;
      color: var(--ld-app-color-on-primary, #ffffff);
    }
  }

  .Signin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .peace-game-signin {
      .lincd_auth_SigninWithPasswordForm_bottomSection {
        display: none;
      }
    }

    div > div > p {
      color: var(--ld-app-color-on-primary, #ffffff);
      &:hover {
        text-decoration-color: var(--secondary-color, #00a6cb);
      }
    }

    button {
      background-color: white !important;
    }

    div > form {
      max-width: 100%;
      border-radius: 8px;
      height: 47px;
    }

    h2 {
      text-transform: capitalize;
      padding-bottom: 10px;
      color: var(--ld-app-color-on-primary, #ffffff);
    }

    .Input {
      width: 100%;
      height: 50px;
      border-radius: 8px;
      border: 1px solid #032265;
      padding: 0 10px;
      box-sizing: border-box;
    }
  }

  .Divider {
    display: flex;
    justify-content: center;
    color: var(--secondary-color, #00a6cb);
  }

  .SocialSignin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    align-items: center;
    position: relative;

    & h3 {
      color: var(--ld-app-color-on-primary, #ffffff);
    }
  }
  .SocialSignin > div:nth-child(1) {
    align-items: center;
    width: 100%;
    display: flex;
  }
  .SocialSignin > div > div:nth-child(1) {
    margin-right: 5px;
  }
  .SocialSignin > div > div:nth-child(1) > button {
    width: 40px;
    border: solid 1px var(--ld-app-color-primary);
    border-radius: 5px;
    height: 40px;
  }

  .SocialSignin > div > input {
    width: 100%;
    height: 40px;
    border: solid 1px var(--ld-app-color-primary);
    border-radius: 5px;
  }

  .SocialSignin > div > div:nth-child(1) > ul {
    border: 1px solid #ccc;
    background: #fff;
    width: 220px;
    padding: 5px;
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 200px;
    z-index: 1;
  }
  .SocialSignin > div > div:nth-child(1) > ul > li {
    //display: flex;
    //align-items: center;
    //gap: 10px;
    //width: 30px;
    //padding: 5px;
  }

  .SocialSignin > div > div:nth-child(1) > ul > li:hover {
    cursor: pointer;
    background-color: #ccc;
    width: 100%;
  }
  .SocialSignin > div > div:nth-child(1) > ul > li > span {
    font-size: 12px;
  }

  .Terms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    max-width: var(--maxWidth - 200px);
    margin: 0 auto;

    & p {
      text-align: center;
      color: var(--ld-app-color-on-primary, #ffffff);
    }
    & a {
      color: var(--ld-app-color-on-primary, #ffffff);
      text-decoration: underline;
      &:hover {
        text-decoration-color: var(--secondary-color, #00a6cb);
      }
    }
    .contactSupport {
      display: flex;
      align-items: center;

      p {
        display: inline-block;
        margin-left: 10px;
        color: var(--ld-app-color-on-primary, #ffffff);
      }

      cursor: pointer;
      margin: 20px auto 1rem auto;
      padding: 0;
      &:hover {
        p {
          text-decoration: underline;
          text-decoration-color: var(--secondary-color, #00a6cb);
        }
      }
    }
  }
}

.switchLanguage {
  padding-top: 1rem;
  color: #fff;
}
