.thumbnail {
  margin: 0.3rem;
  width: 7rem;
  min-width: 150px;
  transition: box-shadow 0.3s;
  border: none;
  background-color: white;
  border-radius: 10px;
  box-shadow: #032265 0 15px 0px 0px;

  > img {
    width: 100%;
    color: #032265;
    border-radius: 10px 10px 0 0;
    height: 70px;
    border-bottom: 2px solid #03226514;
    object-fit: cover;
  }

  > p {
    font-family: 'Anton', 'serif';
    text-align: center;
  }

  // &:hover {
  //   box-shadow: -5px 5px #00a6cb;
  // }
}

.thumbnailText {
  border-radius: 0 0 10px 10px;
  background-color: white;
  padding: 3px;

  > p {
    color: #032265;
    font-family: 'Anton', 'serif';
    text-align: center;

    &:first-child {
      font-size: 0.8rem;
    }
  }
}

.cardContainer {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  margin-bottom: 10px;
  font-size: 11px;
  gap: 10px;
  align-items: center;
  justify-content: space-evenly;

  > p {
    font-family: 'Anton', 'serif';
    color: #fff;
  }

  .value {
    font-size: 30px;
    flex: 1;
    text-align: center;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.cardImage {
  display: flex;
  flex: 1;
  justify-content: center;
}

.bronze {
  box-shadow: -5px 5px var(--bronze);
}

.silver {
  box-shadow: -5px 5px var(--silver);
}

.gold {
  box-shadow: -5px 5px var(--gold);
}
