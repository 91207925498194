.videoContainer {
  position: relative;
  height: 0;
  padding-bottom: var(--videoContainerHeight);
  margin: var(--imageContainerMargin);
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 0px;
}
