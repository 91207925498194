.containerChat {
  overflow-y: hidden;
}

.main {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  margin: auto;
  min-height: 100vh;
  &.whiteBackground {
    background-color: white;
  }
  &.gray {
    background-color: var(--page-color);
  }
  &.white {
    background-color: white;
  }
  &.dark {
    background-color: var(--main-color);
  }
}
