.Container {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 632px) {
    width: calc(100% - 32px);
  }
  @media (min-width: 1024px) {
    width: 1000px;
  }
  @media (min-width: 1280px) {
    width: 1264px;
  }
  //maxWidth?: 'sm' | 'md' | 'lg' | 'xl';
  &.sm {
    width: 600px;
    @media (max-width: 632px) {
      width: calc(100% - 32px);
    }
  }

  &.md {
    width: 1000px;
    @media (max-width: 632px) {
      width: calc(100% - 32px);
    }
  }

  > * {
    flex: 1;
    margin: 0 auto;
    width: 100%;
  }
}

.home {
  padding-left: 0;
  padding-right: 0;
}
