.PlacePreview {
  border-radius: 6px;
  display: flex;
  gap: 5px;
}

.placePreviewText {
  font-family: 'Anton', 'serif';
  text-align: left;
  // margin-left: 16px;
}
.removeIcon {
  cursor: pointer;
}
