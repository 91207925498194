:root {
  font-family: 'Anton', 'serif' !important;
}

//desktop screen
@media only screen and (min-width: 1025px) {
  .bigContainer {
    display: flex;
  }
}

.container {
  height: 100%;
  margin: 0 auto;
  padding: 20px 10px;
  overflow-y: auto;
  background-color: var(--main-color);

  //ipad screen
  @media only screen and (min-width: 768px) {
    margin-top: var(--menu-height);
  }
  //desktop screen
  @media only screen and (min-width: 1025px) {
    margin-top: 0;
    width: calc(100% - var(--main-menu-width));
    right: 0;
    position: absolute;
  }
}

.actionContainer {
  margin: 10px 0;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px !important;
}

.thumbnail {
  margin: 0.3rem;
  width: 7rem;
  min-width: 150px;
  transition: box-shadow 0.3s;
  border: none;
  background-color: white;
  border-radius: 10px;
  box-shadow: #032265 0 15px 0px 0px;

  &:hover {
    box-shadow: -5px 5px #00a6cb;
    transition: box-shadow 0.3s;
  }
  > img {
    width: 100%;
    color: #032265;
    border-radius: 10px 10px 0 0;
    height: 70px;
    border-bottom: 2px solid #03226514;
    object-fit: cover;
  }
  > p {
    font-family: 'Anton', 'serif';
    text-align: center;
  }
}
.bronze {
  box-shadow: -5px 5px #e9946c;
}
.silver {
  box-shadow: -5px 5px #d3d3d8;
}
.gold {
  box-shadow: -5px 5px #eccd21;
}

.thumbnailText > p {
  color: #032265;
  font-family: 'Anton', 'serif';
  text-align: center;
}

.thumbnailText > p:first-child {
  font-size: 0.8rem;
}

.thumbnailText {
  border-radius: 0 0 10px 10px;
  background-color: white;
  padding: 3px;
}
.cardContainer {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  margin-bottom: 10px;
  font-size: 11px;
  gap: 10px;
  align-items: center;
  justify-content: space-evenly;
  > p {
    font-family: 'Anton', 'serif';
    color: #fff;
  }

  .value {
    font-size: 30px;
    flex: 1;
    text-align: center;
  }
}

.cardImage {
  display: flex;
  flex: 1;
  justify-content: center;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
