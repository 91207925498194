.blue {
  color: var(--secondary-color);
  font-weight: var(--default-bold-font-weight, 700);
  // float: left;
  margin-right: 5px;
}

.bold {
  font-weight: var(--default-bold-font-weight, 700);
  & p {
    font-weight: var(--default-bold-font-weight, 700);
  }
  & a {
    color: var(--secondary-color);
    font-weight: var(--default-bold-font-weight, 700);
    &:hover {
      cursor: pointer;
    }
  }
}
