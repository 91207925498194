.buttonJoinTeam {
  margin-top: 10px;
  margin-bottom: 10px;
  &.loading {
    height: 38px;
    & > div {
      transform: translateY(-15px);
    }
  }
}
