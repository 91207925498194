.reportUserPopup {
  position: absolute;
  margin-top: 20px;
  right: 10px;
  button {
    margin-right: 10px;
  }
}
.modal {
  position: relative;
  padding: 25px;
}

.modalContent {
  margin-bottom: 50px;
}

#modalTitle {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 10px;
}

#modalDescription {
  font-size: 1.2rem;
  padding: 10px;
}

#modalDescriptionText {
  font-size: 1.2rem;
  padding: 10px;
  margin-top: 1rem;
}
.reportedInput {
  display: inline;
  margin-bottom: 1rem;
  // height: 23px;
  textarea {
    overflow: hidden;
    border: 1px solid #c8c8c8;
    padding: 5px 10px 1.5rem;
    border-radius: 5px;
    box-sizing: border-box;
  }
}

.reportUser,
.blockUser {
  margin-right: 5px;
  height: 32px;
  width: 32px;
}
