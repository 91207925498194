.Headline {
  width: 100%;
  padding: 20px 0;
  background-color: white;
  box-sizing: border-box;
  color: var(--main-color);
  margin: 25px auto;
  text-align: center;
  text-transform: capitalize;
  &.Inverted {
    background-color: #032265;
    h1,
    h2,
    h3 {
      color: white;
    }
  }
  &.isSubtitle {
    display: flex;
    justify-content: center;
    width: 158px;
    padding: 12px 0;
    background-color: transparent;
  }

  &.blue {
    margin: 20px auto;
    border-top: 2px solid var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
  }

  &.black {
    border-top: 2px solid var(--main-color);
    border-bottom: 2px solid var(--main-color);
    margin: 20px auto;
  }

  .HeadlineWrapper {
    width: 100%;
    height: auto;
    padding: 5px 15px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  // h1 {
  //   margin: auto;

  //   &.small {
  //     font-size: 24px;
  //   }
  // }
}
.subtitle {
  //width: 140px;
  width: min-content;
  white-space: nowrap;
  margin: 0px auto;
  //border-top: 2px solid var(--main-color);
  //border-bottom: 2px solid var(--main-color);
  //padding: 10px 0px;
  //> h3 {
  //  margin-left: -50%;
  //  transform: translateX(15%);
  //  text-wrap: nowrap;
  //}
}
