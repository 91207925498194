.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.usersTable {
  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      position: sticky;
      top: 0;
    }
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 6px;
    font-size: 14px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--secondary-color);
    color: white;
  }
}

.btnGroup {
  display: flex;
  gap: 0.75rem;

  button {
    background-color: var(--secondary-color);
    color: #fff;
    border: 0;
    padding: 3px;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    cursor: pointer;

    svg {
      width: 22px;
    }
  }

  .btnDelete {
    background-color: #c71616;
  }
}

.block {
  padding: 1rem;
}

.filterInput {
  padding: 5px 10px;
  min-width: 350px;
}

.profileUser {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.row {
  margin-bottom: 1.5rem;
  display: block;
}

.contentData {
  margin-top: 1rem;
  display: block;
}
