/**
This file allows you to overwrite specific css classes.
It is configured in such a way that the classes will not be converted to css modules, like the other .scss files.
If you need to target specific classes of your app that you have defined yourself, you'll need to use the converted class name from the .scss.json file

Example: lets say Matches.tsx renders a Material UI component that uses a class "MuiBox-root"
It also imports Matches.scss, which has a class .matchesContainer
If you check the Matches.scss.json file, you'll see that the class name has been converted to .appname_Matches_matchesContainer
So to overwrite styles of "MuiBox-root" you would use:
.appname_Matches_matchesContainer .MuiBox-root {
 */

// start react audio player css
.e05e18 {
  display: none;
}
.ac7b4a {
  border-radius: 4px;
  height: 28px;
  width: 28px;
}
.ac7b4a,
.bff2b5 {
  box-sizing: border-box;
  display: block;
  padding: 4px;
}
.bff2b5 {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
  height: 38px;
  margin: 0;
  outline: none;
  width: 38px;
}
.bff2b5.a45085 .ac7b4a,
.bff2b5:active .ac7b4a {
  background-color: #e5e5e5;
}
.bff2b5.ba5145 {
  opacity: 0.75;
  pointer-events: none;
}
.bff2b5.fde0b7 {
  border: 1px solid #5a5a5a;
}
@media screen and (width <= 480px) {
  .ab5ee0 {
    display: none;
  }
}
.c7b08a {
  color: #333;
  flex-shrink: 0;
  font-family: Helvetica, Verdana, Geneva, sans-serif;
  font-size: 12px;
  line-height: 1;
  margin: 0;
}
.c7b08a,
.e0b58b {
  display: block;
  padding: 0;
}
.e0b58b {
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  height: 38px;
  position: relative;
  width: 100%;
}
.e0b58b.d6d969 {
  display: block;
}
.e0b58b.c0ae57 {
  pointer-events: none;
}
.cc7be8 {
  background-color: #dadada;
  height: 2px;
  left: 6px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: calc(100% - 12px);
  z-index: 0;
}
.c44fc5,
.cc7be8 {
  border-radius: 1px;
}
.c44fc5 {
  background-color: #408af0;
  height: 100%;
  transform-origin: left center;
  width: 100%;
}
.eee1d0 {
  height: 100%;
  left: 6px;
  top: 0;
  width: calc(100% - 24px);
  z-index: 1;
}
.eee1d0,
.f79532 {
  position: absolute;
}
.f79532 {
  background-color: #408af0;
  border-radius: 6px;
  display: block;
  height: 12px;
  margin-top: -6px;
  top: 50%;
  width: 12px;
}
.c0ae57 .f79532 {
  background-color: #dadada;
}
.d6d969 .f79532 {
  box-shadow: 0 0 0 6px rgba(64, 138, 240, 0.2);
}
.f9d0be {
  border: 0;
  cursor: pointer;
  display: block;
  height: 100%;
  left: 6px;
  margin: 0;
  opacity: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 0;
  width: calc(100% - 12px);
  z-index: 2;
}
@media screen and (width <= 480px) {
  .e13c85 {
    display: none;
  }
}
.b8f0f4 {
  display: block;
  flex-shrink: 0;
  height: 38px;
  overflow-x: hidden;
  position: relative;
  width: 38px;
  z-index: 0;
}
.cf10f9 {
  outline: initial;
  pointer-events: none;
}
.f2a1b7 {
  appearance: none;
  background-color: #fff;
  border: 0;
  cursor: pointer;
  display: block;
  font-family: monospace;
  height: 100%;
  opacity: 0;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
.f2a1b7:focus {
  min-width: 100%;
  width: auto;
}
.e22549,
.f2a1b7:focus option:first-child {
  display: none;
}
.ce6c17 {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: normal;
  padding: 6px;
  width: 100%;
}
.e154e9 {
  padding: 0 4px;
}
.cae029 {
  flex-shrink: 0;
  width: 76px;
}
.a3d9e7 {
  display: block;
  outline: 0;
}
@keyframes f9ab9b {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.d9b0ab {
  animation-direction: normal;
  animation-duration: 0.75s;
  animation-fill-mode: none;
  animation-iteration-count: infinite;
  animation-name: f9ab9b;
  animation-play-state: running;
  animation-timing-function: linear;
}
@keyframes c39598 {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.c82f2f {
  align-items: center;
  animation-direction: normal;
  animation-duration: 0.5s;
  animation-fill-mode: none;
  animation-iteration-count: 1;
  animation-name: c39598;
  animation-play-state: running;
  animation-timing-function: linear;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 12px;
  position: static;
  top: 0;
  width: 100%;
  z-index: 5;
}
.da10e0 {
  z-index: auto;
}
.d9b0ab {
  border: 5px solid #f0f0f0;
  border-radius: 100%;
  border-top-color: #444;
  box-sizing: border-box;
  display: block;
}
.bd925c {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.df7adc {
  display: block;
  padding: 0 4px;
}
.da6e9c {
  flex-shrink: 0;
  width: 76px;
}
.b31ddf {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.fa9e1a {
  background-color: #fff;
  box-sizing: border-box;
  display: block;
  padding: 13px 11px 7px;
  position: relative;
  width: 100%;
}
.fa9e1a .bcf8d9 {
  left: 15px;
  top: 19px;
}
.d6f4a6 {
  background-color: #f5f5f5;
  border-bottom: 4px solid #fff;
  border-top: 4px solid #fff;
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}
.b48888 {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-family: Helvetica, Verdana, Geneva, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
  min-height: 48px;
  padding: 0 6px 0 0;
}
.b48888.b28ca0,
.b48888:hover {
  background-color: #e8e8e8;
}
.bbcf1f {
  align-items: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: center;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0;
  width: 48px;
}
.a7a82b,
.bbcf1f,
.ef10a4 {
  flex-shrink: 0;
  position: relative;
}
.a7a82b,
.ef10a4 {
  background-color: #cbcbcb;
  border: 3px solid #cbcbcb;
  border-radius: 100%;
  display: block;
  height: 18px;
  width: 18px;
  z-index: 10;
}
.ef10a4 {
  background-color: #d2dff5;
  border: 3px solid #d2dff5;
}
.c4d018 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-height: 48px;
  overflow: hidden;
  padding: 6px 8px 6px 0;
}
.bef821 {
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.b3c7d7 {
  display: none;
}
.bee42b {
  flex-shrink: 0;
  padding-right: 5px;
}
.c0f54f svg {
  background-color: #fff;
}
.e2ec9c {
  display: none;
}
.a7ad0a {
  display: block;
}
.b59941 {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 6px 22px;
}
.dd564f {
  background-color: #fff;
}
.aec634 {
  display: block;
  padding-bottom: 48px;
}
.aec634:last-child {
  padding-bottom: 12px;
}
.f500e1 {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  color: #333;
  display: block;
  flex-grow: 1;
  font-family: Helvetica, Verdana, Geneva, sans-serif;
  font-size: 18px;
  margin: 0 4px 0 0;
  padding: 3px 11px;
}
.f500e1:active,
.f500e1:hover {
  background-color: #f5f5f5;
  border-color: #333;
}
.a470ec,
.e85b98 {
  background-color: #fff;
  cursor: auto;
  display: block;
  flex-shrink: 0;
  float: right;
  opacity: 0.5;
  pointer-events: none;
}
.a470ec {
  cursor: pointer;
  opacity: 1;
  pointer-events: auto;
}
.fe1637 {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: 4px;
}
.ecef14 {
  background-color: #fff;
  display: block;
  font-size: 24px;
  line-height: 1;
  margin: 0;
  padding: 22px 6px;
  text-align: center;
}
/*# sourceMappingURL=style.css.map*/

button[aria-label='play'] svg {
  background-image: url('/images/player/icons/play-button.svg');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}

button[aria-label='shuffle'],
button[aria-label='repeat'] {
  display: none;
}

button[aria-label='shuffle'] svg {
  background-image: url('/images/player/icons/shuffle.svg');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}
button[aria-label='prev'] svg {
  background-image: url('/images/player/icons/previous-track.svg');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}
button[aria-label='next'] svg {
  background-image: url('/images/player/icons/next-track.svg');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}
button[aria-label='track-list'] svg {
  background-image: url('/images/player/icons/playlist.svg');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
}

button[aria-label='switch-sound'] svg {
  background-image: url('/images/player/icons/speaker-filled-audio-tool.svg');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}
button[aria-label='repeat'] svg {
  background-image: url('/images/player/icons/repeat.svg');
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
}
button[aria-label='pause'] svg {
  background-image: url('/images/player/icons/pause.svg');
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: center;
}

.f79532,
.c44fc5,
.f79532,
.c44fc5 {
  background-color: var(--secondary-color);
}
// end react audio player css

// change text color in modal create account form alert message
.lincd_auth_CreateAccountForm_Alert {
  p {
    color: #fff;
  }
}
