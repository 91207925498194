.statusContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px auto;
  width: min-content;
  gap: 3px;
}

.status {
  display: flex;
  > p {
    transform: translateY(-10px);
    margin-left: 8px;
    margin-right: 8px;
    white-space: nowrap;
  }
}
.center {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  & > img {
    width: 100px;
    height: auto;
  }
}
.centeredText {
  text-align: center;
}
.bold {
  font-weight: bold;
}

.textCenter {
  text-transform: initial;
  margin: 20px auto;
  max-width: 400px;
  text-align: center;
}
