.divider {
  margin-top: 15px;
  text-align: center;
  width: 50%;
  margin-left: 50%;
  transform: translateX(-50%);
  border-top: 2px solid var(main-color);
}
.center {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  button {
    display: flex;
    width: 120px;
    height: 31px;
    border-radius: 5px;
  }
}
