.ResourceContent {
  display: flex;
  flex-direction: column;
  overflow: auto;
  // height: 100vh;
  padding-bottom: 50px;
}
.bannerContainer {
  height: 650px;
  margin-top: 80px;
  max-height: 50vh;
  @media (min-width: 769px) and (max-width: 1024px) {
    height: 500px;
    margin-top: 0px;
  }
  @media (max-width: 768px) {
    height: 300px;
    margin-top: 0px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
    box-shadow: 0 0 0 10vmax white;
    clip-path: inset(0 -10vmax);
  }
}
.button {
  position: fixed;
  font-size: 8px;
  text-transform: uppercase;
  top: 80vh;
  right: 10px;
  background-color: var(--main-color) !important;
  color: #ffffff;
  border: none;
  width: auto;
  height: 60px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 5;
}

.Content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 var(--imageContainerMargin);
  padding: 0 var(--containerPadding);

  // img {
  //   width: 100%;
  //   height: 30vh;
  //   object-fit: cover;
  //   box-shadow: 0 0 0 10vmax white;
  //   clip-path: inset(0 -10vmax);
  // }

  // .ContentTitle {
  //   text-align: center;
  //   margin: 20px 0px;
  // }
}
