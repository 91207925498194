.debriefContent {
  max-width: var(--maxWidth);
  margin: 40px auto 0;
  // padding: var(--contentPadding);

  li {
    padding-top: var(--liPaddingTop);
    padding-bottom: var(--liPaddingBottom);
    margin-left: var(--liMarginLeft);

    p {
      text-indent: 0em;
    }
  }
  p {
    margin-bottom: 0.5rem;
  }

  div > div:nth-child(1) {
    background-color: transparent !important;
  }

  > div:last-child {
    .textArea {
      margin-bottom: 0;
    }
  }

  .textArea {
    min-height: 100px;
    margin-bottom: 2rem;
    font-size: 1rem;
  }
}
