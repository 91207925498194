//HouseholdScoreboard Section
.HouseholdScoreboard {
  display: flex;
  flex-direction: column;
  background-color: var(--main-color);

  .Header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--main-color);

    .Title {
      padding: 10px 10px;
      box-sizing: border-box;
      color: white;
    }
  }

  .Content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;

    .ScoreboardTable {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;

      .TableHeader {
        display: flex;

        @media (max-width: 480px) {
          white-space: nowrap;
          gap: 5px;
          margin: 2rem auto;
        }
        div {
          display: flex;
          flex-direction: column;
          flex: 1;
          width: 33.33%;
          align-items: center;

          h5 {
            color: white;
          }
        }
      }

      .TableContent {
        display: flex;
        flex: 1;
        width: 100%;
        flex-direction: column;
        .HouseholdScoreboardTopicThumbnail {
          margin: 10px;
          box-sizing: border-box;
        }
      }
    }
  }

  .scoreItem {
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: space-evenly;
    align-items: center;
    > h3 {
      color: white;
      .percentIcon {
        width: 60px;
      }
    }
    > * {
      width: 40%;
      text-align: center;
      @media (max-width: 512px) {
        width: 50%;
      }
    }
    @media (max-width: 420px) {
      flex-direction: column;
      margin-bottom: 3rem;
      gap: 0;
      > * {
        width: 100%;
      }
    }
  }
}
