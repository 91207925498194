.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 1rem;

  &.modalContent {
    max-width: 100%;
    max-height: 100%;
  }
}

.block {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.filterInput {
  padding: 5px 10px;
  min-width: 300px;
  font-size: 14px;
  border: solid 1px var(--secondary-color);
  border-radius: 4px;
}

.modalContent {
  min-width: 700px;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  padding: 1.5rem;

  p {
    font-size: 14px;
    line-height: 1.5;
  }
}

.profileUser {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.row {
  margin-bottom: 1.5rem;
  display: block;
}

.teamRow {
  outline: 0;
}

.actionRow {
  margin: 1rem 0;

  &:first-of-type {
    margin-top: 0.5rem;
  }

  div {
    padding: 10px;
  }

  h6 {
    margin-bottom: 0;
  }
}

.actionList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 -10px;
  gap: 1rem;

  > div {
    background-color: #f5f6f7;
    border: solid 1px #eee;
    border-radius: 2px;
  }
}

.teamHeadline {
  background-color: var(--dark-purple);
  color: #fff;
  padding: 5px 10px;
  border-radius: 2px;
}

.contentData {
  margin-top: 1rem;
  display: block;
}

.sticky {
  position: sticky;
  top: 0;
}

.formSkipEmail {
  display: flex;
  gap: 1rem;
  justify-content: center;

  input {
    min-width: 300px;
    padding: 8px;
  }
}

.searchFilter {
  width: 200px;
  position: relative;
  z-index: 4;
  font-size: 14px;
}

.mergeAccountBtn {
  position: sticky;
  bottom: -3rem;
  text-align: center;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  gap: 1rem;

  &.show {
    bottom: 1rem;
    opacity: 1;
    visibility: visible;
  }
}

.searchAction {
  display: flex;
  gap: 2rem;
}

.showDetailBtn {
  margin-left: 0.5rem;
  font-size: 12px;
  font-weight: 400;
}
