.spinner {
  margin: auto;

  img {
    width: 50px;
    height: 50px;
    max-width: 100%;
  }

  &.centered {
    position: fixed;
    left: calc(50% - 8px);
    top: calc(50% - 8px);
  }
}
