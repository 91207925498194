.textfield {
  margin: 5px 0;
  background-color: var(--page-color);
  //padding: 0;
  //border: 1px solid var(--secondary-color);
  //border-radius: 5px;
  //text-indent: 10px;
  //height: 40px;
}
.unstyled {
  text-transform: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  margin-right: 10px;
}
