.container {
  margin: auto;
  padding: 20px auto;
  img {
    margin: 0 50%;
    transform: translateX(-50%);
    width: 100px;
  }
  p {
    font-weight: bold;
  }
  .certificate {
    padding-bottom: 50px;
    p {
      text-align: center;
    }
  }
}
