.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 0;
  margin-top: 1rem;
  box-sizing: border-box;
  max-width: var(--maxWidth);
}

.startBtn {
  margin: 0 auto;
  display: flex;
  transform: none;
}

.customTitle {
  font-size: 27px;
}

.customHeadline {
  background-color: transparent;
}

.blueArea {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--secondary-color);
  font-weight: bold;

  p {
    color: inherit;
    font-weight: inherit;
  }

  ol {
    margin-left: 20px;
    color: inherit;
    // gap: 20px;

    li {
      color: inherit;
      font-weight: inherit;
      margin-bottom: 10px;
      line-height: 25px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .copyButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      box-sizing: border-box;

      p {
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  }

  .teamNumber {
    font-size: 30px;
    text-align: center;
  }
}

.errorForm {
  color: red;
  font-size: 14px;
}

.formSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 10px;
  justify-content: space-between;

  .inlineForm {
    display: flex;
    gap: 10px;
    width: 100%;
    padding: 10px 0;
  }
}

.inputWrapper {
  position: relative;
  width: 100%;
  // padding: 10px 0;
  // max-width: 125px;

  input {
    border: 1px solid var(--main-color);
    // text-transform: capitalize;
    border-radius: 0;
    // padding: 12px 10px;
    font-size: 16px; // set on App.scss
    // height: 39px;

    &:focus {
      box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01),
        0px 0px 8px rgba(0, 0, 0, 0.2);
    }
  }
}

.inputField > input {
  height: 30px;
  padding: 0.75rem 1rem;
  width: -webkit-fill-available;
  font-family: 'Anton', 'serif';
}

.addTeammateButton {
  margin: 0 auto;
  gap: 10px;
  font-family: 'Roboto', 'sans-serif';
  font-size: 14px;
  text-wrap: nowrap;
  border: 2px solid var(--main-color) !important;
  color: var(--main-color) !important;
  z-index: 5;
  height: 50px;
  width: 50%;
  text-transform: uppercase;
  font-weight: 500 !important;
  &:hover {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    background-color: var(--soft-light-teal) !important;
  }
  &.left {
    left: 20%;
  }
  &.right {
    right: 20%;
  }
  &.loading {
    height: 38px;
    & > div {
      transform: translateY(-15px);
    }
  }
}

.member {
  ul {
    // border: 2px solid blue;
    margin: 0 0 0 15px;
  }
}

.closeIcon {
  background-color: transparent;
  border: 0;
  margin-left: 5px;
  padding: 5px;
  cursor: pointer;
}

.modalButtonContainer {
  display: flex;
  justify-content: flex-end !important;
  gap: 10px;
  margin-top: 20px;
}

.modal {
  padding: 1.5rem;
  max-width: min(480px, 90vw);
}
