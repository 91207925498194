:root {
  --lincd-text-field-helper-text-color: var(--lincd-color-secondary);
}

.TextField {
  font-family: var(--lincd-font-family-primary);
  font-weight: 400;
  border-radius: var(--lincd-input-textfield-border-radius);
  color: var(--lincd-color-primary);
  background-color: var(--lincd-input-background-color);
  border: var(--lincd-input-border);
  box-shadow: 0px 2px 2px #f3f6f9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: var(--lincd-color-secondary);
  }

  &:focus-within {
    border-color: var(--lincd-color-secondary);
    box-shadow: 0 0 0 3px #f3f6f9;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }

  > input,
  > textarea {
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;
    flex-grow: 1;
    color: inherit;
    background: inherit;
    border: none;
    border-radius: inherit;
    padding: 0.75rem 1rem;
    outline: 0;
  }

  > input {
    &[type='email'] {
      text-transform: lowercase;
    }
  }

  &.error {
    > input,
    > textarea {
      border-color: var(---lincd-color-error);
      box-shadow: 0 0 3px var(---lincd-color-error);
    }
  }
}

.helperText {
  font-size: 0.85rem;
  color: var(--lincd-text-field-helper-text-color);
  margin: 5px 0;
  font-family: 'Nunito', sans-serif;
}

.spinner {
  position: absolute;
  width: 35px;
  right: 20px;
  margin-top: 10px;
}
