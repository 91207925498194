.content {
  max-width: var(--maxWidth);
  margin: 50px auto 0 auto;
  padding: var(--contentPadding);
  p {
    text-transform: initial !important;
    margin-top: 20px;
  }
  &.certainMode {
    padding-bottom: 5rem;
  }
}

.bold {
  font-weight: bold;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue {
  color: var(--secondary-color);
  font-weight: 700;
}
