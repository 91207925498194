.PeaceActionContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.listItem {
  min-width: 100%;
  margin: 0 auto !important;
  @media only screen and (min-width: 371px) {
    width: 100%;
    height: min-content;
  }
}

.createTeamBtn {
  margin: 0 auto;
  display: flex;
  transform: none;
}

.mainContainer {
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 8px;
}
.mainContainer {
  margin: 1rem 0;
}
//desktop screen
@media only screen and (min-width: 1025px) {
  .mainContainer {
    position: absolute;
    width: calc(100% - var(--main-menu-width));
    right: 0;
    top: calc(var(--safe-area-top) + var(--menu-height));
  }
}
