.TeamActionContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .Title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      width: 50px;
    }
  }

  .JoinTeam {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    max-width: var(--maxWidth);
    margin: 0 auto;

    img {
      width: 100%;
      height: 30vh;
      object-fit: cover;
    }

    p {
      font-size: 18px;
    }
  }

  .StartTeam {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      width: 100%;
    }

    p {
      font-size: 18px;
    }
  }
}
