.menuContainer {
  width: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  @media (max-width: 460px) {
    flex-direction: column;
  }
  @media (max-width: 460px) {
    > *,
    button {
      width: 100%;
    }
  }
}

.buttonContainer {
  //display: flex;
  //align-items: center;
  //justify-content: space-evenly;
  gap: 10px;
  font-family: 'Roboto', 'sans-serif';
  font-size: 14px;
  text-wrap: nowrap;
  border: none !important;
  color: var(--main-color) !important;
  z-index: 5;
  //width: 120px;
  height: 50px;
  text-transform: uppercase;
  font-weight: 500 !important;
  &:hover {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    background-color: var(--soft-light-teal) !important;
  }

  &.left {
    left: 20%;
  }
  &.right {
    right: 20%;
  }
}
