.root {
  text-align: center;
  padding: 0.5rem 1rem 2rem;

  select {
    padding: 5px;
    border-color: var(--main-color);
    border-radius: 6px;
    font-size: 15px;
    color: var(--main-color);
  }
}
