.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
}

.startBtn {
  margin: 0 auto;
  display: flex;
  transform: none;
}

.hyperlink {
  color: var(--secondary-color);
  text-decoration: none;
  font-weight: bold;
}
