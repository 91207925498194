.JoinTeamContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 50px;

  .buttonJoinTeam {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .Title {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 50px;
    }
  }

  form > * {
    margin: 10px 0px;
  }

  .subText {
    text-align: center;
    margin: 30px 0;
  }
}
