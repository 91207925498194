@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:ital,wght@0,400;0,500;0,900;1,400;1,500;1,900&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

h1 {
  color: var(--main-color);
  text-align: center;
  font-family: Anton;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.544px;
  text-transform: uppercase;
}

h2 {
  color: var(--main-color);
  font-family: Anton;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.442px;
}

h3 {
  color: var(--main-color);
  font-family: Anton;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.442px;
}

h4 {
  color: var(--secondary-color);
  font-family: Anton;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.255px;
}

h5 {
  color: var(--main-color);
  text-align: center;
  font-family: Anton;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.238px;
  text-transform: uppercase;
}

h6 {
  color: var(--secondary-color);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

p,
li {
  color: var(--main-color);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
