.PeaceGameVideos {
  display: block;
}
.content {
  max-width: var(--maxWidth);
  margin: 50px auto;
  padding: var(--contentPadding);

  p {
    text-transform: initial !important;
    margin-top: 20px;
  }
}
