.banner {
  width: 100%;
}
.content {
  max-width: var(--maxWidth);
  margin: 50px auto 0 auto;
  padding: var(--contentPadding);
  p {
    text-transform: initial !important;
    margin-top: 20px;
  }
}
.blue {
  color: var(--secondary-color);
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}
.bold {
  font-weight: bold;
}
.divider {
  margin-top: 15px;
  text-align: center;
  width: 50%;
  margin-left: 50%;
  transform: translateX(-50%);
  border-top: 2px solid var(--main-color);
}
