.container {
  border-radius: 8px;
  overflow: hidden;
  width: 480px;
  max-height: 90vh;
}

.content {
  background-color: white;
  padding: 1.25rem;
  overflow-y: scroll;
  max-height: 70vh;
  .container2 {
    & div {
      margin-bottom: 1rem;
    }
  }
}

.title {
  display: flex;
  background-color: var(--secondary-color);
  min-height: 100px;
  img {
    width: 150px;
    object-fit: cover;
  }
  .avatarPersonal {
    width: 150px;
    object-fit: contain;
    border-radius: 0px;
    height: 100px;
  }
}

.card {
  display: flex;
}

.header {
  width: 100%;
  text-align: center;
  align-self: center;
  color: white;
  font-size: xx-large;
  font-family: 'Anton', 'serif';
}

@media (max-width: 580px) {
  .container {
    width: auto;
  }
  .header {
    font-size: x-large;
  }
}

.container2 {
  border-radius: 8px;
  h2,
  p {
    color: var(--main-color);
    display: flex;
    font-family: 'Anton', 'serif';
  }
}

.card2 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  justify-items: center;
  align-items: center;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 580px) {
      h2 {
        font-size: larger;
      }
    }
  }

  &__image {
    width: 80px;
    object-fit: contain;
  }

  @media (max-width: 580px) {
    &__image {
      width: 70px;
      height: 70px;
    }
  }
}
