.container {
  border: 0;
}
.title {
  border-bottom: 1px solid black;
  width: 100%;
  padding-top: 30px;
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
}

.profileUploaderWrapper {
  max-width: 125px;
}

.inputContainer {
  position: relative;
  padding: 10px 0;
  // max-width: 125px;

  input {
    border: 1px solid var(--main-color);
    // text-transform: capitalize;
    border-radius: 0;
    padding: 12px 10px;
    font-size: 16px; // set on App.scss
    // height: 39px;

    &:focus {
      box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01),
        0px 0px 8px rgba(0, 0, 0, 0.2);
    }
  }
}

.inputField > input {
  height: 30px;
  padding: 0.75rem 1rem;
  width: -webkit-fill-available;
  font-family: 'Anton', 'serif';
}
