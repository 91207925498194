.content {
  max-width: var(--maxWidth);
  margin: 50px auto 0 auto;
  padding: var(--contentPadding);
  p[_tolgee='true'] {
    text-transform: initial !important;
    margin-top: 20px;
    display: inline;
  }

  p {
    text-transform: initial !important;
    margin-top: 20px;
  }

  div {
    margin-top: 20px;
  }
}

.bold {
  font-weight: bold;
}

.originImage {
  width: 80%;
  margin: var(--imageContainerMargin);

  p {
    text-align: center;
    font-size: var(--imageAltTextSize);
  }

  img {
    margin: 0 auto;
    display: block;
    width: 100%;
  }
}
