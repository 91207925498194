.list > li {
  margin-bottom: 5px;
  list-style-type: none;
  text-indent: -2em;
  padding-left: 2em;
}

.list > li::before {
  content: '';
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--secondary-color);
  /* Change the color */
  font-weight: 700;
  /* If you want it to be bold */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin: 0 0.5em;
  /* Also needed for space (tweak if needed) */
  height: 1em;
  position: relative;
  top: 0.5rem;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='5' viewBox='0 0 5 5' fill='none'%3E%3Ccircle cx='2.5' cy='2.5' r='2' stroke='%2300A6CB'/%3E%3C/svg%3E");
}
