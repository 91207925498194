.ResourceDetailSingleListItem {
  position: relative;
}

.listItem {
  min-width: 100%;
}

//desktop screen
@media only screen and (min-width: 1025px) {
  .mainContainer {
    width: calc(100% - var(--main-menu-width));
    right: 0;
    top: 0;
    position: absolute;
  }
}

.customMenu {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
