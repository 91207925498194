.peaceTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin: 0 70px;
  margin-top: 20px;

  gap: 10px;
  h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: white;
    flex: 1;
    justify-content: center;
    min-width: 150px;
    text-align: center;
    &:last-child {
      min-width: auto;
    }
  }
}
