.GetStartedContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .banner {
    width: 100%;
    box-shadow: 0 0 0 10vmax white;
    clip-path: inset(0 -10vmax);
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
  }

  @media (min-width: 210px) and (max-width: 600px) {
    .banner img {
      height: 200px;
    }
  }

  .Title {
    display: flex;
    // justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      width: 65px;
    }

    .Subtitle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 5px;
      box-sizing: border-box;

      p {
        font-size: 10px;
      }
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    gap: 10px;
    max-width: var(--maxWidth);
    margin: var(--imageContainerMargin);
    padding: var(--containerPadding);

    img {
      width: 100%;
      height: 30vh;
      object-fit: cover;
    }

    .ContentTitle {
      text-align: center;
      margin: 20px 0px;
    }
  }
}

.videoContainer {
  position: relative;
  height: 0;
  padding-bottom: var(--videoContainerHeight);
  margin: auto;
  width: 100%;
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 0px;
}
.blue {
  color: var(--secondary-color);
  font-weight: var(--default-bold-font-weight);
  cursor: pointer;
  &:hover {
    color: var(--main-color);
  }
}
.bold {
  font-weight: var(--default-bold-font-weight);
}
