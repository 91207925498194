@import 'lincd/lib/scss/variables.scss';

:root {
  --ld-source: #032265;
  /* primary */
  --ld-ref-palette-primary0: #000000;
  --ld-ref-palette-primary10: #00201f;
  --ld-ref-palette-primary20: #003736;
  --ld-ref-palette-primary25: #004342;
  --ld-ref-palette-primary30: #00504e;
  --ld-ref-palette-primary35: #005d5b;
  --ld-ref-palette-primary40: #006a68;
  --ld-ref-palette-primary50: #008583;
  --ld-ref-palette-primary60: #00a19f;
  --ld-ref-palette-primary70: #20bebb;
  --ld-ref-palette-primary80: #4ddad7;
  --ld-ref-palette-primary90: #6ff7f4;
  --ld-ref-palette-primary95: #affffc;
  --ld-ref-palette-primary98: #e3fffd;
  --ld-ref-palette-primary99: #f1fffe;
  --ld-ref-palette-primary100: #ffffff;
  /* secondary */
  --ld-ref-palette-secondary0: #000000;
  --ld-ref-palette-secondary10: #3f0017;
  --ld-ref-palette-secondary20: #5f112b;
  --ld-ref-palette-secondary25: #6e1d36;
  --ld-ref-palette-secondary30: #7c2841;
  --ld-ref-palette-secondary35: #8b344c;
  --ld-ref-palette-secondary40: #9a4058;
  --ld-ref-palette-secondary50: #b95870;
  --ld-ref-palette-secondary60: #d87189;
  --ld-ref-palette-secondary70: #f78aa3;
  --ld-ref-palette-secondary80: #ffb1c1;
  --ld-ref-palette-secondary90: #ffd9df;
  --ld-ref-palette-secondary95: #ffecee;
  --ld-ref-palette-secondary98: #fff8f7;
  --ld-ref-palette-secondary99: #fffbff;
  --ld-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --ld-ref-palette-tertiary0: #000000;
  --ld-ref-palette-tertiary10: #2e004e;
  --ld-ref-palette-tertiary20: #461969;
  --ld-ref-palette-tertiary25: #522674;
  --ld-ref-palette-tertiary30: #5e3281;
  --ld-ref-palette-tertiary35: #6b3e8e;
  --ld-ref-palette-tertiary40: #774a9b;
  --ld-ref-palette-tertiary50: #9263b6;
  --ld-ref-palette-tertiary60: #ad7dd1;
  --ld-ref-palette-tertiary70: #c997ee;
  --ld-ref-palette-tertiary80: #e1b6ff;
  --ld-ref-palette-tertiary90: #f2daff;
  --ld-ref-palette-tertiary95: #fbecff;
  --ld-ref-palette-tertiary98: #fff7fd;
  --ld-ref-palette-tertiary99: #fffbff;
  --ld-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --ld-ref-palette-neutral0: #000000;
  --ld-ref-palette-neutral10: #191c1c;
  --ld-ref-palette-neutral20: #2d3131;
  --ld-ref-palette-neutral25: #383c3c;
  --ld-ref-palette-neutral30: #444747;
  --ld-ref-palette-neutral35: #4f5353;
  --ld-ref-palette-neutral40: #5b5f5e;
  --ld-ref-palette-neutral50: #747877;
  --ld-ref-palette-neutral60: #8e9191;
  --ld-ref-palette-neutral70: #a9acab;
  --ld-ref-palette-neutral80: #c4c7c6;
  --ld-ref-palette-neutral90: #e0e3e2;
  --ld-ref-palette-neutral95: #eff1f0;
  --ld-ref-palette-neutral98: #f7faf9;
  --ld-ref-palette-neutral99: #fafdfc;
  --ld-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --ld-ref-palette-neutral-variant0: #000000;
  --ld-ref-palette-neutral-variant10: #141d1d;
  --ld-ref-palette-neutral-variant20: #293232;
  --ld-ref-palette-neutral-variant25: #343d3d;
  --ld-ref-palette-neutral-variant30: #3f4948;
  --ld-ref-palette-neutral-variant35: #4a5454;
  --ld-ref-palette-neutral-variant40: #566060;
  --ld-ref-palette-neutral-variant50: #6f7978;
  --ld-ref-palette-neutral-variant60: #889392;
  --ld-ref-palette-neutral-variant70: #a3adac;
  --ld-ref-palette-neutral-variant80: #bec9c7;
  --ld-ref-palette-neutral-variant90: #dae5e3;
  --ld-ref-palette-neutral-variant95: #e8f3f2;
  --ld-ref-palette-neutral-variant98: #f1fbfa;
  --ld-ref-palette-neutral-variant99: #f4fefd;
  --ld-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --ld-ref-palette-error0: #000000;
  --ld-ref-palette-error10: #410002;
  --ld-ref-palette-error20: #690005;
  --ld-ref-palette-error25: #7e0007;
  --ld-ref-palette-error30: #93000a;
  --ld-ref-palette-error35: #a80710;
  --ld-ref-palette-error40: #ba1a1a;
  --ld-ref-palette-error50: #de3730;
  --ld-ref-palette-error60: #ff5449;
  --ld-ref-palette-error70: #ff897d;
  --ld-ref-palette-error80: #ffb4ab;
  --ld-ref-palette-error90: #ffdad6;
  --ld-ref-palette-error95: #ffedea;
  --ld-ref-palette-error98: #fff8f7;
  --ld-ref-palette-error99: #fffbff;
  --ld-ref-palette-error100: #ffffff;

  /* light colors */
  /* primary */
  --ld-app-color-primary: var(--secondary-color);
  --ld-app-color-primary-lighter: var(--ld-ref-palette-primary80);
  --ld-app-color-primary-darker: var(--main-color);
  --ld-app-color-on-primary: #ffffff;
  --ld-app-color-primary-container: #6ff7f4;
  --ld-app-color-on-primary-container: #00201f;

  /* secondary */
  --ld-app-color-secondary: var(--ld-ref-palette-secondary80);
  --ld-app-color-secondary-lighter: var(--ld-ref-palette-secondary90);
  --ld-app-color-secondary-darker: var(--ld-ref-palette-secondary70);
  --ld-app-color-on-secondary: #ffffff;
  --ld-app-color-secondary-container: #ffd9df;
  --ld-app-color-on-secondary-container: #3f0017;

  /* tertiary */
  --ld-app-color-tertiary-darker: var(--ld-ref-palette-tertiary30);
  --ld-app-color-tertiary: var(--ld-ref-palette-tertiary40);
  --ld-app-color-tertiary-lighter: var(--ld-ref-palette-tertiary60);
  --ld-app-color-on-tertiary: #ffffff;
  --ld-app-color-tertiary-container: #f2daff;
  --ld-app-color-on-tertiary-container: #2e004e;

  --ld-app-color-error: #ba1a1a;
  --ld-app-color-error-container: #ffdad6;
  --ld-app-color-on-error: #ffffff;
  --ld-app-color-on-error-container: #410002;
  --ld-app-color-background: #fafdfc;
  --ld-app-color-on-background: #191c1c;
  --ld-app-color-surface: #fafdfc;
  --ld-app-color-on-surface: #191c1c;
  --ld-app-color-surface-variant: #dae5e3;
  --ld-app-color-on-surface-variant: #3f4948;
  --ld-app-color-outline: #6f7978;
  --ld-app-color-inverse-on-surface: #eff1f0;
  --ld-app-color-inverse-surface: #2d3131;
  --ld-app-color-inverse-primary: #4ddad7;
  --ld-app-color-shadow: #000000;
  --ld-app-color-surface-tint: #006a68;
  --ld-app-color-outline-variant: #bec9c7;
  --ld-app-color-scrim: #000000;

  /* dark */
  --ld-app-color-primary-dark: #4ddad7;
  --ld-app-color-on-primary-dark: #003736;
  --ld-app-color-primary-container-dark: #00504e;
  --ld-app-color-on-primary-container-dark: #6ff7f4;
  --ld-app-color-secondary-dark: #ffb1c1;
  --ld-app-color-on-secondary-dark: #5f112b;
  --ld-app-color-secondary-container-dark: #7c2841;
  --ld-app-color-on-secondary-container-dark: #ffd9df;
  --ld-app-color-tertiary-dark: #e1b6ff;
  --ld-app-color-on-tertiary-dark: #461969;
  --ld-app-color-tertiary-container-dark: #5e3281;
  --ld-app-color-on-tertiary-container-dark: #f2daff;
  --ld-app-color-error-dark: #ffb4ab;
  --ld-app-color-error-container-dark: #93000a;
  --ld-app-color-on-error-dark: #690005;
  --ld-app-color-on-error-container-dark: #ffdad6;
  --ld-app-color-background-dark: #191c1c;
  --ld-app-color-on-background-dark: #e0e3e2;
  --ld-app-color-surface-dark: #191c1c;
  --ld-app-color-on-surface-dark: #e0e3e2;
  --ld-app-color-surface-variant-dark: #3f4948;
  --ld-app-color-on-surface-variant-dark: #bec9c7;
  --ld-app-color-outline-dark: #889392;
  --ld-app-color-inverse-on-surface-dark: #191c1c;
  --ld-app-color-inverse-surface-dark: #e0e3e2;
  --ld-app-color-inverse-primary-dark: #006a68;
  --ld-app-color-shadow-dark: #000000;
  --ld-app-color-surface-tint-dark: #4ddad7;
  --ld-app-color-outline-variant-dark: #3f4948;
  --ld-app-color-scrim-dark: #000000;

  //other alias values, specific for LINCD (the ones above are inspired by Material Design)
  //Remember, aliases should point from a -ld-app variable to a -ld-ref variable
  --ld-app-box-spacing: var(--ld-ref-spacing-m);
  --ld-app-box-border-radius: var(--ld-ref-spacing-m);
  --ld-app-box-border-width: 1px;
  --ld-app-box-border-color: var(--ld-ref-palette-neutral-variant50);

  --ld-app-body-background-color: var(--neutral-background);
  --ld-app-body-text-family: Roboto;
  --ld-app-body-text-size: --ld-ref-font-size-l;

  --ld-app-body-text-color: var(--main-color);
  --ld-app-body-text-color-lighter: var(--dark-purple);
  --ld-app-body-text-color-lightest: var(--dark-purple);

  //TODO: refactor the custom variables
  --ld-app-hyperlink-font-color: var(--secondary-color);
  --ld-app-color-active: var(--dark-purple);

  //!! CUSTOM VARIABLES - THIS WILL NOT SCALE INTO THE FUTURE AND WILL NEED TO BE REFACTORED AT SOME POINT
  //BETTER TO USE THE VARIABLES ABOVE

  //Container padding
  --containerPadding: 20px 16px 20px 16px;
  --maxWidth: 600px;
  --contentPadding: 0 1.6rem;
  --imageContainerMargin: 20px auto;
  --videoContainerHeight: 62.5%;

  //li padding for ordered and unordered lists
  --liPaddingTop: 0.2em;
  --liPaddingBottom: 0.2em;
  --liMarginLeft: 1em;

  //Text
  --imageAltTextSize: 12px;
  --default-bold-font-weight: 700;

  /* specify Peace Game colors */
  --main-color: #032265; //dark blue
  --secondary-color: #00a6cb; //dark teal
  --tertiary-color: #cef8ff; //light teal
  --page-color: #f2f4f9;
  --dark-purple: #324f8f;
  --soft-light-teal: #e7f0fc;
  --bronze: #e9946c;
  --silver: #d3d3d8;
  --gold: #eccd21;
  --neutral-background: #f5f5f5;

  --main-menu-width: 220px;
}
