.Root {
  position: relative; /* Required for positioning the custom arrow */
  display: inline-block;
  width: 100%;
}

.select {
  height: 36px;
  width: 100%;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  width: -webkit-fill-available;
  font-family: 'Anton', 'serif';
  color: var(--main-color);
  padding-left: 10px;

  /* reset the select */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:focus {
    border-color: var(--secondary-color);
    outline: 0;
  }
}

.option {
  padding: 10px;
}
.arrow {
  position: absolute;
  right: 10px; /* Adjust position as needed */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Allows */
  svg {
    fill: hsl(0, 0%, 80%);
  }
}
