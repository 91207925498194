.teamRow {
  outline: 0;
}

.teamHeadline {
  background-color: var(--dark-purple);
  color: #fff;
  padding: 5px 10px;
  border-radius: 2px;
}

.sticky {
  position: sticky;
  top: 0;
}
