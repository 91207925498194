.container {
  position: fixed;
  max-width: 120px;
  right: 25px;
  bottom: 20px;
  background: var(--secondary-color);
  padding: 10px;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  img {
    height: 48px;
  }

  p {
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  @media (max-width: 768px) {
    bottom: 100px;
    img {
      height: 36px;
    }
    p {
      font-size: 14px;
    }
  }
}
