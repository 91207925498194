.Root {
  position: relative;

  @media (max-width: 552px) {
    width: 100%;
  }
}

.topicCard {
  cursor: pointer;
}
