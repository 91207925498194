.Thumbnail {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 200px;
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: -6px 6px #032265;

  .ThumbnailImage {
    display: flex;
    height: 50%;
    min-width: 100%;
    border-radius: 12px 12px 0 0;

    img {
      min-width: 100%;
      height: auto;
      border-radius: 12px 12px 0 0;
      object-fit: cover;
    }
  }

  .ThumbnailContent {
    display: flex;
    flex-direction: column-reverse;
    min-width: 100%;
    background-color: #f0f0f0;
    border-radius: 0 0 12px 12px;
    padding: 3px;
    box-sizing: border-box;

    .ContentHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100%;

      &.withBadges {
        justify-content: space-between;
      }

      h5 {
        text-transform: capitalize;

        &.SmallTitle {
          font-size: 12px;
          line-height: 10px;
        }
      }

      .ContentIcon {
        display: flex;
        gap: 3px;
        justify-content: flex-end;
      }
    }

    .ContentAction {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100%;

      h3 {
        text-transform: uppercase;

        &.Category {
          text-transform: capitalize;
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
}
