.wrapper {
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: flex-end;
}

.chatWrapper {
  flex: 1;
  position: fixed;
  top: calc(80px + var(--safe-area-top, 0px)); // header height + safe area
  left: 0;
  right: 0;
  bottom: calc(0px + var(--safe-area-bottom, 0px)); // 0px + safe area
}

.chatMessages {
  opacity: 1;
  height: 100%;
  visibility: visible;

  // hidden CometChatMessages when thread is open
  &.isHidden {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }
}

.chatHeader {
  position: fixed;
  top: var(--safe-area-top, 0px);
  z-index: 100;
}

.chatSpinner {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.chatWelcome {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.6);
  z-index: 9999; /* Adjust z-index as needed */
  .chatWelcomeInner {
    margin: 0 auto;
    background: #fff;
    width: 400px;
    height: 250px;
    display: flex;
    align-items: center;
    text-align: center;
    border: 1px solid #eeee;
    flex-direction: column;
    box-shadow: 0 15px 22px #eee;
    border-radius: 10px;
    justify-content: center;

    button {
      font-size: 20px;
      margin-top: 15px;
    }
  }
}

.chatContainer {
  position: relative;
  .chatMember {
    position: absolute;
    top: 20px;
    z-index: 999;
    right: 20px;
    &:hover {
      cursor: pointer;
    }
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

.closeMember {
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 100;
  &:hover {
    cursor: pointer;
  }
}

.chatMemberList {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  animation: slideDown 0.3s ease forwards;
  visibility: hidden;
}

.chatMemberList.active {
  visibility: visible;
}

.memberGroupCount {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0px;
  h4 {
    font-weight: 400;
    color: gray;
  }
  img {
    width: 24px;
    height: 24px;
    top: 25px;
    right: 30px;
  }
}

.reportUser {
  position: absolute;
  right: 10px;
}

.reportedInput {
  display: inline;
  margin-bottom: 1rem;
  // height: 23px;
  textarea {
    overflow: hidden;
    border: 1px solid #c8c8c8;
    padding: 5px 10px 1.5rem;
    border-radius: 5px;
    box-sizing: border-box;
  }
}

.reportUser,
.blockUser {
  margin-right: 5px;
  height: 32px;
  width: 32px;
}

.reportUserPopup {
  position: absolute;
  margin-top: 20px;
  right: 10px;
  button {
    margin-right: 10px;
  }
}
