.Meeting02 {
  display: block;
}
.content {
  max-width: var(--maxWidth);
  margin: 50px auto;
  padding: var(--contentPadding);

  li {
    padding-top: var(--liPaddingTop);
    padding-bottom: var(--liPaddingBottom);
    margin-left: var(--liMarginLeft);

    p {
      text-indent: 0em;
    }
  }
  p {
    margin-bottom: 1em;
  }
}
