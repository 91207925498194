:root {
  //@TODO: should border color be darker variant of primary? new app variable?
  --lincd-input-inputfield-border-radius: var(--ld-app-box-border-radius);
  --lincd-input-inputfield-border-color: var(--ld-app-box-border-color);
  --lincd-input-inputfield-border-width: var(--ld-app-box-border-width);
  --lincd-input-inputfield-background-color: var(--ld-app-color-on-primary);
  --lincd-input-inputfield-text-font: var(--ld-app-body-text-family);
  --lincd-input-inputfield-text-size: var(--ld-ref-font-size-m);
}

.LocationInputRoot {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: var(--ld-app-body-text-family);
  font-size: var(--ld-app-body-text-size);
  border-radius: var(--ld-app-box-border-radius);
  cursor: pointer;

  border: solid 1px var(--ld-app-box-border-color);
  background-color: var(--ld-app-box-background-color);
  color: var(--ld-button-primary-font-color);

  &:hover,
  &.active {
    background-color: var(--ld-button-primary-hover-background-color);
  }
}

.listItem {
  cursor: pointer;
  & > p {
    text-align: start !important;
    font-family: 'anton', serif;
  }
}

.listItem:hover {
  cursor: pointer;
  background-color: var(--ld-ref-palette-neutral95);
}

.locationResult {
  margin-top: 0;
  box-shadow: 2px 2px 4px var(--ld-ref-palette-neutral95);
  border-radius: 0;
  width: 100%;
  background-color: #fff;
  z-index: 5;
  position: absolute;

  ul {
    list-style: none;
  }

  ul li {
    list-style: 0;
    text-align: start;
    padding: 10px 15px;

    p {
      font-size: 14px;
      list-style-type: none;
      width: 100%;
      p {
        margin-left: 10px;
      }
    }

    ul li:hover {
      background-color: #f5f5f5;
      background-color: var(--main-color);
      cursor: pointer;
      p {
        color: white;
      }
    }
  }
}
