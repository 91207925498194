.InviteYourTeamContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  // border: 2px solid red;

  .Title {
    display: flex;
    // justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      width: 65px;
    }

    .Subtitle {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 5px;
      box-sizing: border-box;

      p {
        font-size: 10px;
      }
    }
  }

  .Content {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    gap: 20px;

    // p {
    //   font-weight: 100;
    // }

    img {
      width: 100%;
    }

    .ContentTitle {
      text-align: center;
      margin: 0;
    }

    .ContentMain {
      display: flex;
      flex-direction: column;

      .contentCopied {
        display: flex;
        flex-direction: row;
      }

      .copyButton {
        height: 100%;
        width: 32px;
        object-fit: contain;
        margin-left: 40px;
        // animated when :hover
        &:hover {
          cursor: pointer;
          transform: scale(1.1);
          transition: all 0.3s ease-in-out;
        }
      }

      p,
      ol {
        color: var(--secondary-color);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        // line-height: ;
      }

      li {
        // margin-bottom: 8px;
        margin: 0 18px;
      }
    }

    .ContentBottom {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}
