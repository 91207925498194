.actionRow {
  margin: 1rem 0;

  &:first-of-type {
    margin-top: 0.5rem;
  }

  h6 {
    margin-bottom: 0;
  }
}

.actionList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 -10px;
  gap: 1rem;
  padding: 10px;

  &.showDetail {
    grid-template-columns: repeat(1, 1fr);
  }

  &.noSpace {
    padding: 0;
  }

  > div {
    background-color: #f5f6f7;
    border: solid 1px #eee;
    border-radius: 2px;
    padding: 10px;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
  }
}
