.block {
  text-align: center;

  .wrapper {
    padding: 2rem;
    width: 600px;
    margin: auto;
    border: solid 2px var(--main-color);
    background-color: #f5f6f7;
    border-radius: 8px;
  }

  form {
    margin-top: 1rem;

    p {
      margin-bottom: 5px;
    }

    label {
      text-align: left;
    }

    input {
      margin-right: 10px;
    }

    button {
      margin-top: 1rem;
    }
  }
}

.alertSuccess {
  max-width: 400px;
  margin: 1rem auto 2rem;
  background-color: #d4fedc;
  padding: 1rem;
  text-align: center;

  p,
  a {
    font-size: 14px;
    line-height: 1.5;
  }
}
