.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.33%;
  height: 100%;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  h1 {
    font-size: xxx-large;
  }
  @media (min-width: 320px) and (max-width: 430px) {
    h1 {
      font-size: xx-large;
    }
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }
}
.description {
  p {
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
  }
}

@media (min-width: 320px) and (max-width: 430px) {
  .description p {
    font-size: x-small;
  }
}
