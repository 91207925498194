.JoinTeamContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .Title {
    display: flex;
    // justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      width: 50px;
    }
  }

  .Form {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    gap: 10px;

    .ContactForm {
      box-sizing: border-box;

      p {
        font-size: 18px;
      }
    }

    .Signin {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .Input {
        width: 100%;
        height: 50px;
        border-radius: 8px;
        border: 1px solid #032265;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }

    .FormItem {
      margin: 16px auto;
      border-bottom: 1px solid #8e8e8e;
      overflow: hidden;
      // border: 2px solid green;
    }

    .FormInput {
      display: flex;
      align-items: center;
      margin: 10px;
      width: 100%;
      outline: none;
      border: none;

      input::placeholder {
        font-size: 15px;
      }
    }
  }

  .SocialSignin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    align-items: center;
  }

  .OauthLogo {
    width: 36px;
    height: 36px;
    background-size: cover;
  }

  .GoogleLogo {
    background-image: url(/images/google-logo.svg);
    background-size: 28px 28px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .EmailLogo {
    background-image: url(/images/email.png);
    background-size: 28px 28px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .FacebookLogo {
    background-image: url(/images/fb-logo.svg);
    background-size: 28px 28px;
    background-position: center;
    background-repeat: no-repeat;
  }
}
