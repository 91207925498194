.JoinTeamContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .banner {
    //box-shadow: 0 0 0 10vmax white;
    clip-path: inset(0 -10vmax);
    width: 100%;
    height: 30vw;
    object-fit: cover;
    object-position: 0 -230px;
  }

  @media only screen and (max-width: 480px) {
    .banner {
      object-position: 0 -80px;
    }
  }

  @media (min-width: 481px) and (max-width: 680px) {
    .banner {
      object-position: 0 -120px;
    }
  }

  @media (min-width: 681px) and (max-width: 920px) {
    .banner {
      object-position: 0 -230px;
    }
  }
  @media (min-width: 921px) {
    .banner {
      object-position: 0 -270px;
    }
  }

  .Form {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    gap: 10px;
    max-width: var(--maxWidth);
    margin: 0 auto;
    width: 100%;

    .ContactForm {
      box-sizing: border-box;

      p {
        font-size: 18px;
      }
    }

    .FormItem {
      display: flex;
      flex-direction: column;
      margin: 16px auto;
      gap: 15px;

      label {
        font-size: 14px;
        color: rgb(144, 144, 144);
        display: inline-block;
        margin-bottom: 0.5rem;
      }

      .Input {
        width: 100vw;
        height: 50px;
        border: 1px solid var(--main-color);
        box-sizing: border-box;
        // font-size: 16px; // set on App.scsss

        // this style only for input date on app
        // remove arrow input date android browser
        &.inAppInput {
          input[type='date'] {
            padding: 11px 22px;
            text-indent: 25px;
            appearance: none;
            -webkit-appearance: none;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button,
            &::-webkit-calendar-picker-indicator {
              display: none;
              appearance: none;
              -webkit-appearance: none;
            }
          }
        }

        input {
          text-indent: 30px;

          &[type='date'] {
            text-indent: 35px;

            // date field text left on ios
            &::-webkit-date-and-time-value {
              text-align: left;
            }
          }
        }
      }

      .BottomLinedInput {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid var(--main-color);
        border-top: none;
        border-left: none;
        border-right: none;
        box-sizing: border-box;
        // font-size: 16px; // set on App.scsss
      }

      .inputFieldContainer {
        .errorValidation {
          color: red;
          font-size: 14px;
          margin-top: 5px;
        }
      }
    }
  }
  .inputFieldContainer > label {
    text-transform: none;
  }

  .inputField {
    display: flex;
    position: relative;
    & > img {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
    }
    & > input[type='text'],
    & > input[type='tel'] {
      text-indent: 40px;
    }
    & > input[type='date'] {
      text-indent: 20px;
    }

    .calendarBig {
      width: 35px;
      height: 35px;
      margin-top: 1px;

      &.inAppIcon {
        margin-top: 0;
        top: 7px;
        left: 7px;
      }
    }
  }

  .uploadIconContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 70px;
    height: auto;
    justify-content: center;
    & > p {
      text-transform: none;
      font-size: 12px !important;
      text-wrap: nowrap;
    }
  }
  .errorValidationInput {
    color: red;
    font-size: 12px;
    margin-top: -20px;
  }
}

.phoneNumberControl {
  display: block;
  opacity: 1;
}

.phoneNumberField {
  display: block;
}

.datepickerField {
  width: 100%;

  > div {
    display: block;
  }
}

.inputLocation {
  input {
    border-radius: 0;
    height: 50px;
    font-size: 16px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 10px 15px;

    &:focus-visible {
      outline: none;
    }
  }
}

.datepicker {
  display: flex;

  // react-date-picker__wrapper
  > div {
    flex-direction: row-reverse;
    position: relative;
    background-color: #fff;
    height: 50px;
    border-color: var(--main-color);

    // react-date-picker__inputGroup__divider
    // prettier-ignore
    span[class="react-date-picker__inputGroup__divider"] {
      padding-left: 3px;
      padding-right: 3px;
    }

    button {
      // clear button
      &:first-of-type {
        background-color: transparent;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
      }

      // calendar icon button
      &:last-of-type {
        background-color: transparent;
      }
    }
  }
}

.calendar {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  > div {
    // react-calendar__navigation
    &:first-of-type {
      button {
        font-size: 1rem;

        // react-calendar__navigation__label
        &:nth-child(3) {
          font-weight: bold;
        }
      }
    }
  }
}

.customErrorValidation {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
