.container {
  max-width: var(--maxWidth);
  margin: 50px auto;
  padding: var(--contentPadding);
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  box-sizing: border-box;
  p {
    text-transform: initial !important;
    margin-top: 20px;
  }
}

.row {
  display: grid;
  grid-template-columns: 7fr 1fr;
}

.generateAllButton {
  margin-top: -30px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.teamRow {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}

.table {
  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid #ddd;
      padding: 5px;
      text-align: center;
    }

    th {
      font-size: 15px;
      background-color: var(--main-color);
      color: white;
    }

    td {
      font-size: 14px;
    }
  }
}
